<template lang="html">
    <div class="container inputOutput">
        <top-navigation-bar
            title="Two Proportions"
            right-nav="home"
        />
        <h4 class="mb-3 text-center divider">
            What do you want to test?
        </h4>
        <div class="m-view d-flex py-4">
            <h2>H<sub>A</sub> :  p<sub>1</sub> </h2>
            <select
                v-model="selected.value"
                class="form-select-lg mx-3"
            >
                <option
                    v-for="(option, key) in options"
                    :key="key"
                >
                    {{ option.value }}
                </option>
            </select>
            <h2>p<sub>2</sub></h2>
        </div>
        <div class="sticky-bottom m-3 mt-4">
            <button
                class="action-btn btn-block"
                block
                @click="onSubmit()"
            >
                Next
            </button>
        </div>
    </div>
</template>

<script>
import TopNavigationBar from '@/components/top-navigation-bar.vue'
import localStore from '@/store/localStore.js'

export default {
    name: 'TwoProportionChoice',
    components: {
        TopNavigationBar,
    },
    data () {
        return {
            computeType: 'hypothesis',
            computationPicked: 'TwoP',
            options: [
                { value: '<' },
                { value: '>' },
                { value: '≠' },
            ],
            selected: { value: '>' }
        }
    },
    mounted () {
        var store = localStore.getStore('hypothesis-data-choice-twoP') || ''
        if (store.choice) {
            this.selected = store.choice.selected
        }
    },
    methods: {
        onSubmit () {
            var { computeType, computationPicked, selected } = this
            var choice = { computeType, computationPicked, selected }
            localStore.setStoreItem('hypothesis-data-choice-twoP', { choice })
            this.$router.push({ name: 'hypothesis/two-proportion-input' })
        },
    },
}
</script>
